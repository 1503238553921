import React from 'react';
import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';
import { Grid, Box, Typography, Link } from '@material-ui/core';
import ImageContainer from '../../../../components/image-container';
import SectionContainerLayoutWithFilter from '../../../../components/section-container-layout-with-filter';

export default function PlanYouTrip() {
  return (
    <Layout>
      <SEO lang='en' title='Plan Your Trip' />
      <SectionContainerLayoutWithFilter isViewAll title='PLAN YOUR TRIP' baseLink='/press/plan-your-trip' isDivider>
        <Grid container>
          <Box position='relative' clone>
            <Grid item xs={12}>
              <ImageContainer filename='travel-information-2' altText='plan-your-trip' />
              <Box
                position='absolute'
                left='0'
                style={{
                  top: '50%',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(0 , 0 , 0 , 0.5)',
                }}>
                <Box p={2}>
                  <Typography variant='h3' style={{ fontWeight: 'bold' }}>
                    Travel Information
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>

          <Grid item xs={12}>
            <Box p={2} height='100%'>
              <Typography variant='h6'>
                <br />
                <b>For travel arrangements:</b> (Visas, Flights, Airport Transfers, Accommodation, PCR tests while in
                UAE, Tours, others)
                <br />
                <br />
                Airlink International UAE
                <br /> P.O. Box 10466, Airlink Building, Airport Road, Al Garhoud, Dubai, U.A.E.
                <br />
                Tel: +971 4 282 1050 (Ext. 238)
                <br /> Fax: +971 4 282 0416
                <br />
                Contact Person: Mr Hassan Taha <br />
                Senior Travel Consultant
                <br /> E-mail: <Link href='mailto: fsafar@airlink.ae'>fsafar@airlink.ae</Link>
                <br /> <br />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
}
